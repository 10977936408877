import { useTranslation } from 'react-i18next';

import RegistrationLayout from 'components/shared/Registration/RegistrationLayout';
import VerifyOTPForm from 'components/VerifyOTP/Form';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { getUser } from 'api/auth';
import { useMutation, useQuery } from 'react-query';
import { setLocalStorage } from 'utils/localStorage';
import { useToast } from '@chakra-ui/react';
import {
  getOnboardingState,
  updateOnboardingState as updateOnboardingStateAPI,
} from 'api/onboarding';

const VerifyOTP = () => {
  const { confirmOTP, isConfirmOtpLoading, confirmOtpError } = useAuth();

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();

  const email = location.state?.email || t('yourEmail');

  const {
    mutate: updateOnboardingState,
    isLoading: isUpdateOnboardingStateLoading,
  } = useMutation(updateOnboardingStateAPI, {
    onSuccess: (data) => {
      navigate('/');
    },
  });

  const { isLoading: isOnboardingDataLoading, refetch: getOnboardingData } =
    useQuery('onboarding-data', getOnboardingState, {
      enabled: false,
      onSuccess: (data) => {
        const utmTags = Object.fromEntries(
          new URLSearchParams(location.search)
        );

        updateOnboardingState({
          onboardingStateId: data.id,
          onboarding: {
            state: {
              utmTags,
            },
          },
        });
      },
    });

  const { refetch: getUserQuery } = useQuery('user-me', getUser, {
    onSuccess: (data) => {
      setLocalStorage('user', data);

      toast({
        title: t('yourEmailHasBeenVerified'),
        status: 'success',
        duration: 5000,
      });

      getOnboardingData();
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const handleSubmit = ({ verificationCode }) => {
    confirmOTP(verificationCode, {
      onSuccess: () => getUserQuery(),
    });
  };

  const isLoading =
    isConfirmOtpLoading ||
    isUpdateOnboardingStateLoading ||
    isOnboardingDataLoading;

  return (
    <RegistrationLayout
      title={t('enterVerificationCode')}
      subtitle={`${t('weSentVerificationCodeTo')} ${email}`}
      content={
        <VerifyOTPForm
          onSubmit={handleSubmit}
          responseError={confirmOtpError}
          isLoading={isLoading}
        />
      }
    />
  );
};

export default VerifyOTP;
